import React, {useState, useEffect, useRef} from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import {Checkbox} from 'primereact/checkbox';
import {Toast} from "primereact/toast";
import { Calendar } from 'primereact/calendar';
import { generateCronExpression } from '../../../utils/utils';
import { BUSINESS } from '../../../shared/ProviderScheduledType';
import ApiLoan from '../../../service/ApiLoanService';
import TemplateOverlay from "../../Templates/TemplateOverlay";

const BusinessForm = (props) => {
    const [action, setAction] = useState('create');
    const [method, setMethod] = useState(null);
    const [platformId, setPlatformId] = useState('');
    const [urlPage, setUrlPage] = useState('');
    const [active, setActive] = useState(false);
    const [authorization, setAuthorization] = useState({
        authorization_type: null,
        value: ''
    });
    const [loadingTest, setLoadingTest] = useState(false);
    const [time, setTime] = useState('');
    const [frecuencyTime, setFrecuencyTime] = useState('30');
    const [frecuencyTimeOptions, setFrecuencyTimeOptions] = useState([]);
    const methodOptions = [
        { value: 'POST', label: 'POST' },
        { value: 'PATCH', label: 'PATCH' }
    ];
    const [showTemplateWhatsappDialog, setShowTemplateWhatsappDialog] = useState(false);
    const providerScheduledAction = BUSINESS;
    const [errors, setErrors] = useState({})
    const [disableSubmit, setDisableSubmit] = useState(true)

    useEffect(() => {
        let time_options = [];

        let time_labels = {
            '30': '30 minutos',
            '60': '1 hora',
            '120': '2 horas',
            '180': '3 horas',
            '300': '5 horas',
            '480': '8 horas',
        }

        for (let key in time_labels) {
            time_options.push({
                'value':  key,
                'label': time_labels[key]
            });
        }
        setFrecuencyTimeOptions(time_options);
    }, []);

    useEffect(() => {
        let form_errors = {}
        if (time === ''){
            form_errors['time'] = 'Hora Inválida'
        }
        if (frecuencyTime === ''){
            form_errors['frecuency'] = 'Frecuencia Inválida'
        }
        if (platformId === ''){
            form_errors['platformId'] = 'ID Pip Inválido'
        }
        if (method === null){
            form_errors['method'] = 'Método Inválido'
        }
        if (urlPage === ''){
            form_errors['urlPage'] = 'Url Inválida'
        }
        let valid = true
        Object.values(form_errors).map(x => {
            if (Object.values(x).length > 0){
                valid = false;
            }
            return x
        });

        setErrors(form_errors);
        setDisableSubmit(!valid);
    }, [time, frecuencyTime, platformId, method, urlPage]);

    useEffect(() => {
        let apiService = new ApiLoan();
        let edit = props.match.params.id !== undefined;
        if (edit){
            apiService.getResource({
                url: '/provider_scheduled/',
                resource_id: props.match.params.id
            }).then(response => {
                const data = response.data;
                setMethod(data.method);
                setUrlPage(data.url);
                setActive(data.active);
                let _body = JSON.parse(data.body)
                if (_body) {
                    setTime(new Date(_body['time']));
                    setFrecuencyTime(_body['frecuency_time']);
                    setPlatformId(_body['platform_id']);
                }
            });
            setAction('edit');
        } else {
            apiService.getResources({
                url: '/provider_scheduled',
                filters: [
                    {"name": "provider_scheduled_action_id", "op": "eq", "val": providerScheduledAction}
                ]
            }).then(response => {
                if (response.data.objects.length === 1){
                    refToast.current.show({severity: 'error', summary: 'Mensaje Programado',
                        detail: 'Ya existe una configuración del Mensaje Programado.'});
                    setTimeout(() => {
                        props.history.push('/provider_scheduled')
                    }, 3000);
                }
            });
        }
    }, []);


    const testHandleSubmit = (event) => {
        event.preventDefault();
        setLoadingTest(true);
        let detail = 'Se ejecutó correctamente.'

        const apiService = new ApiLoan();
        const formData = {}

        apiService.postResource({
            'url': '/provider/scheduled/test/ping',
            'data': formData
        }).then(response => {
            if (response?.data?.message !== undefined) {
                detail = response?.data?.message
            }
            refToast.current.show({severity: 'success', summary: 'Mensaje Programado', detail: detail});
        }).catch(error => {
            let error_message = error?.response?.data?.message;
            if (typeof(error?.response?.data?.message) === 'object'){
                error_message =  'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'Mensaje Programado',
                detail: error_message});
        }).finally(() => {setLoadingTest(false)} );
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        const apiService = new ApiLoan();

        const formData = {
            'method': method,
            'url': urlPage,
            'provider_scheduled_action_id': providerScheduledAction,
            'provider_scheduled_period_id': null,
            'active': active,
            'body': {'platform_id': platformId, 'time': time, 'frecuency_time': frecuencyTime },
            'authorization': authorization,
            'frecuency': time === '' ? null : generateCronExpression(time, frecuencyTime)
        }

        let api_request = null;
        let url = 'provider_scheduled';
        if (action === 'create'){
            api_request = apiService.postResource;
        } else {
            api_request = apiService.patchResource;
            url = url + '/' + props.match.params.id
        }

        api_request({
            'url': url,
            'data': formData
        }).then(response => {
            refToast.current.show({severity: 'success', summary: 'Mensaje Programado', detail: 'Se creó correctamente.'});
            props.history.push('/')
        }).catch(error => {
            let error_message = error?.response?.data?.message;

            if (typeof(error.response.data.message) === 'object'){
                error_message =  'Verifique los datos del formulario.'
            }
            refToast.current.show({severity: 'error', summary: 'Mensaje Programado',
                detail: error_message});
        });
    }

    const getFieldError = (field) => {
        let form = null;
        if (errors[field]){
            form = <small style={{color:'#f44336'}} className="p-error"> {errors[field]} </small>
        }
        return form;
    }

    const selectTemplateWhatsappHandler = (e) => {
        setShowTemplateWhatsappDialog(true)
    }

    const updateAttribute = (value, field, setField) => {
        setField(value)
        let form_errors = {...errors}
        if (form_errors[field]){
            delete form_errors[field]
        }
        setErrors(form_errors)
    }

    const refToast = useRef(null);
    const labelSubmit = props.match.params.id !== undefined ? 'Guardar' : 'Crear';
    let form = (
        <div className="grid">
            <div className="col-12 md-12">
                <div className="card p-fluid">
                    <h1><strong>Mensajes Programados</strong></h1>
                    <Toast ref={refToast}/>
                    <div className="formgrid grid">
                        <div className="field col-1">
                            <span className="p-float-label">
                                <Calendar value={time} stepMinute={0} readOnlyInput={true}
                                          onChange={(e) => updateAttribute(e.value, 'time', setTime) } timeOnly/>
                                <label>Hora Importación</label>
                            </span>
                            {getFieldError('time')}
                        </div>
                        <div className="field col-1">
                            <span className="p-float-label">
                                <Dropdown value={frecuencyTime}
                                          options={frecuencyTimeOptions}
                                          onChange={(e) => updateAttribute(e.value, 'frecuencyTime', setFrecuencyTime)}/>
                                <label>Frecuencia de envio</label>
                            </span>
                            {getFieldError('frecuencyTime')}
                        </div>
                        <div className="field col-3">
                            <span className="p-float-label">
                                <InputText id="inputtext" value={platformId}
                                           required={true}
                                           onChange={(e) => updateAttribute(e.target.value, 'platformId', setPlatformId)}/>
                                <label htmlFor="inputtext">ID Pip</label>
                            </span>
                            {getFieldError('platformId')}
                        </div>
                        <div className="field col-1">
                            <span className="p-float-label">
                                <Dropdown id="dropdown" value={method} options={methodOptions}
                                          onChange={(e) => updateAttribute(e.value, 'method', setMethod)}/>
                                <label htmlFor="dropdown">Método</label>
                            </span>
                            {getFieldError('method')}
                        </div>
                        <div className="field col-3">
                            <span className="p-float-label">
                                <InputText id="inputtext" value={urlPage}
                                           onChange={(e) => updateAttribute(e.target.value, 'urlPage', setUrlPage)}/>
                                <label htmlFor="inputtext">Url</label>
                            </span>
                            {getFieldError('urlPage')}
                        </div>
                        <div className="field col-1">
                             <span className="p-float-label">
                                <Button label={'Plantillas'}
                                        icon="pi pi-eye"
                                        onClick={(e) => selectTemplateWhatsappHandler(e)}>
                                </Button>
                            </span>
                        </div>
                        <div className="field col-1">
                             <span className="p-float-label">
                                <Button label={'Test Conexión'} disabled={true} severity='secondary'
                                        icon="pi pi-plus"

                                        onClick={(e) => testHandleSubmit(e)}>
                                </Button>
                            </span>
                        </div>
                        <div className="field col mb-1 pb-5">
                            <span className="p-float-label mt-2">
                                <Checkbox checked={active} onChange={e => setActive(e.checked)}/>
                                <label className={"ml-3"}>Habilitado</label>
                            </span>
                        </div>
                    </div>

                    <div className="field p-fluid">
                        <Button label={labelSubmit} className={"mt-4"} disabled={disableSubmit}
                            onClick={(e) => handleSubmit(e)}/>
                    </div>
                </div>

                <TemplateOverlay
                    showTemplates={showTemplateWhatsappDialog}
                    setShowTemplates={setShowTemplateWhatsappDialog}
                    onTemplateSelection={() => { return null}}
                    onTemplateDelete={() => { return null}}
                    onGetTemplates={() => { return null}}
                    onTemplateUpdate={() => { return null}}
                    onTemplateError={() => { return null}}
                    onTemplateCreate={() => { return null}}
                    canCreateCustomVariables={true}
                    businessIntegration={true}
                />

            </div>
        </div>
    )
    return form;
}

export default BusinessForm;