import React from 'react'
import Provider from "./Provider";

const providerEdit = (props) => {

    const resource = {
        title: 'Editar Cliente',
        url: '/clients/',
        provider_id: props.match.params.id,
        fields: [
            'name', 'address', 'email', 'phone', 'cbu', 'cuit', 'show_remote_loan', 'show_debtor_qr_code',
            'private_inbox', 'private_resource', 'webhook_enabled', 'chatbot_enabled', 'interactive_message', 'domain_name',
            'payment_service', 'provider_payment_service', 'auto_assign_inbox', 'available', 'gateway', 'gateway_waba',
            'whatsapp_max_notification', 'max_users', 'mr_turno_integration', 'business_integration', 'ai_assistant_integration', 'export_clients_enabled', 'internal_chat',
            {
                logo: {
                    elementType: 'file_upload',
                    elementConfig: {
                        allowMultiple: false,
                        maxFiles: 1,
                        acceptedFileTypes: ['image/jpeg', 'image/png'],
                        allowImagePreview: true,
                        name: 'logo'
                    }
                },
            }
        ],
        action: 'edit'
    };

    return (
        <Provider title={resource.title} url={resource.url} fields={resource.fields}
                  resource_id={resource.provider_id} action={resource.action}/>
    );
};

export default providerEdit;