import React, { Component } from 'react';
import InputForm from '../../../UI/InputForm';
import { Button } from 'primereact/button';
import ApiLoan from '../../../service/ApiLoanService';
import {withRouter} from "react-router-dom";

import {showToast} from "../../../store/actions";
import {showSuccessMessage, showErrorMessage} from "../../../shared/messagesToast";
import connect from "react-redux/es/connect/connect";
import {messageProviderErrorObject} from "../../../shared/utility";
import axios from "axios";
import CustomSpinner from "../../../UI/CustomSpinner";
import update from "immutability-helper";

class Provider extends Component {

    _isMounted = false;

    state = {
        apiService: new ApiLoan(),
        url: null,
        uri: '/clients',
        title: 'Cliente',
        providerForm: {
            available: {
                elementType: 'switch',
                elementConfig: {
                },
                value: true,
                traduction: 'Habilitado'
            },
            slug: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Slug'
            },
            name: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Nombre Completo'
            },
            address: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Dirección'
            },
            email: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'email'
                },
                value: '',
                traduction: 'Email'
            },
            phone: {
                elementType: 'text',
                elementConfig: {
                },
                value: '',
                traduction: 'Teléfono'
            },
            cbu: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'CBU'
            },
            cuit: {
                elementType: 'text',
                elementConfig: {
                    keyfilter: 'pint'
                },
                value: '',
                traduction: 'CUIT'
            },
            domain_name: {
                elementType: 'dropdown',
                elementConfig: {
                    options: [
                        {
                            'value': 'pip.com.ar',
                            'label': 'Pip'
                        },
                        {
                            'value': 'prontocobro.com',
                            'label': 'Prontocobro'
                        },
                        {
                            'value': 'dev.pip.com.ar',
                            'label': 'Pip DEV'
                        },
                        {
                            'value': 'cl.pip.com.ar',
                            'label': 'Pip CL'
                        },
                    ],
                },
                value: '',
                traduction: 'Dominio'
            },
            gateway: {
                elementType: 'dropdown',
                elementConfig: {
                    options: [
                        {
                            'value': 'WABOXAPP',
                            'label': 'WABOXAPP'
                        },
                        {
                            'value': 'DIALOG_360',
                            'label': 'DIALOG_360'
                        },
                        {
                            'value': 'OPEN-WA',
                            'label': 'OPEN-WA'
                        },
                        {
                            'value': 'CLOUD-API',
                            'label': 'CLOUD-API'
                        }
                    ],
                    showClear: true
                },
                value: null,
                traduction: 'Gateway'
            },
            gateway_waba: {
                elementType: 'whatsappGatewayService',
                elementConfig: {
                },
                dependenceOf: [
                    'gateway'
                ],
                colsClass: {
                    input: "col-12 md-9"
                },
                value: {}
            },
            whatsapp_max_notification: {
                elementType: 'whatsappMaxNotification',
                elementConfig: {
                },
                dependenceOf: [
                    'gateway'
                ],
                colsClass: {
                    input: "col-12 md-9"
                },
                value: 0
            },
            max_users: {
                elementType: 'number',
                elementConfig: {
                    min: 0,
                    max: 200
                },
                colsClass: {
                    input: "col-12 md-9"
                },
                traduction: 'Cantidad de usuarios',
                value: 0
            },
            show_remote_loan: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Referencia en vista deudor'
            },
            show_debtor_qr_code: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Código QR en vista deudor'
            },
            private_inbox: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Bandeja privada'
            },
            auto_assign_inbox: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Asignador Automático'
            },
            private_resource: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Recursos Privados'
            },
            webhook_enabled: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Habilitar Webhooks'
            },
            chatbot_enabled: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Habilitar Bot'
            },
            interactive_message: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Mensaje interactivo (CLOUD)'
            },
            payment_service: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Servicio de Pago'
            },
            mr_turno_integration: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Mr Turno'
            },
            business_integration: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Integración Business'
            },
            internal_chat: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Chat Interno'
            },
            ai_assistant_integration: {
                elementType: 'switch',
                elementConfig: {
                },
                value: false,
                traduction: 'Asistente IA'
            },
            export_clients_enabled: {
                elementType: 'switch',
                elementConfig: {
                },
                value: true,
                traduction: 'Exportar Clientes'
            },
            provider_payment_service: {
                elementType: 'providerPaymentService',
                elementConfig: {
                },
                dependenceOf: [
                    'payment_service'
                ],
                colsClass: {
                    input: "col-12 md-9"
                },
                value: []
            },
        },
        loading: true,
        providerFormEdit: null,
        files: null,
        server: null,
        providerPaymentServiceFormError: {
            errorProviderPaymentService: []
        },
        providerWhatsappGatewayServiceFormError: false
    };


    componentDidMount(){
        this._isMounted = true;

        if (this.props.action === 'edit'){
            this.state.apiService.getResource({
                url: this.props.url,
                resource_id: this.props.resource_id
            }).then(response => {
                if (this._isMounted) {
                    let fetched_resource = {};
                    let response_data = response.data;
                    let provider_form = {
                        ...this.state.providerForm
                    };
                    this.props.fields.map(providerField => {

                        let field = Object.keys(response_data).filter(x => x === providerField)[0];

                        if (field !== undefined){
                            provider_form[field].value = response.data[field];
                        }

                        if (field === 'payment_service'){
                            if (response.data[field]){
                                let provider_payment_service = response.data['provider_payment_service'];
                                let gateways = {};

                                response.data['provider_payment_service'].gateways.map(gatewayTemp => {
                                    let gateway = {
                                        'active': gatewayTemp.active,
                                        'value': gatewayTemp.percentage
                                    };
                                    gateways[gatewayTemp.gateway] = gateway;
                                    return gateway;
                                });

                                provider_payment_service.gateways = gateways;

                                provider_form['provider_payment_service'].value = provider_payment_service
                            }
                        }

                        if (providerField['logo']){
                            provider_form['logo'] = providerField['logo'];
                        }
                        delete provider_form['slug'];
                        fetched_resource = provider_form;
                        return fetched_resource;
                    });

                    this.setState({
                        providerFormEdit: fetched_resource,
                        title: this.props.title,
                        server: {
                            url: axios.defaults.baseURL + '/clients/'+this.props.resource_id+'/logo',
                            load: (uniqueFileId, load, error) => {
                                fetch(uniqueFileId,
                                    {
                                        headers: new Headers({
                                            'Authorization': 'Bearer ' + localStorage.getItem('token')
                                        })
                                    })
                                    .then(res => {
                                        if (res.status !== 200){
                                            this.setState({
                                                files: null
                                            });
                                        }
                                        return res.blob();
                                    })
                                    .then(load)
                                    .catch(error)
                            },
                            process: {
                                withCredentials: true,
                                timeout: 240000,
                                headers: {
                                    Authorization: 'Bearer '+ localStorage.getItem('token')
                                }
                            },
                            revert: null
                        },
                        loading: false,
                        files: [{
                            source: axios.defaults.baseURL + '/clients/'+this.props.resource_id+'/logo',
                            options: {
                                type: 'local'
                            }
                        }],

                    });
                }
            }).catch(error => {
                showErrorMessage(this, "Clientes", 'Error en la carga del cliente');
                this.props.history.push( '/clients' );
            });
        } else {
            this.setState({
                loading: false
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateColsClass = (providerForm) => {
        let provider_payment_service = {
            ...providerForm.provider_payment_service
        };

        let updatedColsClass = update(provider_payment_service, {
            colsClass: {
                input: {
                    $set: "col-12 md-12"
                }
            }
        });

        providerForm['provider_payment_service'] = updatedColsClass;
        return providerForm;

    };

    providerHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formData = {};

        for (let formElementIdentifier in this.state.providerForm) {
            formData[formElementIdentifier] = this.state.providerForm[formElementIdentifier].value;
        }

        this.state.apiService.postResource({
            url: this.state.uri,
            data: formData
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Cliente", "Se creó satisfactoriamente");
            this.props.history.push( '/clients' );
        }).catch(error => {
            let messageError = error.response.data.message;

            if (messageError.gateway_waba !== undefined){
                showErrorMessage(this, "Whatsapp", 'Error en la configuración de whatsapp');
                delete messageError.gateway_waba
            }

            let providerForm = {
                ...this.state.providerForm
            };

            let providerPaymentServiceFormError = {
                ...this.state.providerPaymentServiceFormError
            };

            Object.keys(providerPaymentServiceFormError.errorProviderPaymentService).map(error => {
                if (!(error in messageError)){
                    delete providerPaymentServiceFormError.errorProviderPaymentService[error];
                }
                return providerPaymentServiceFormError;
            });

            let errorMessages = messageProviderErrorObject(providerForm, providerPaymentServiceFormError, messageError);

            let providerPaymentServiceError = {'errorProviderPaymentService': null};
            if (errorMessages !== null){
                providerPaymentServiceError['errorProviderPaymentService'] = errorMessages.errorProviderPaymentService;

                delete errorMessages.errorProviderPaymentService;
                errorMessages = this.updateColsClass(errorMessages);
                this.setState({
                    providerForm: errorMessages,
                    providerPaymentServiceFormError: providerPaymentServiceError,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Cliente", messageError);
            }
        });
    };

    providerEditHandler = (event) => {
        event.preventDefault();
        this.setState( { loading: true } );

        const formDataEdit = {};

        for (let formElementIdentifier in this.state.providerFormEdit) {
            formDataEdit[formElementIdentifier] = this.state.providerFormEdit[formElementIdentifier].value;
        }

        this.state.apiService.patchResource({
            url: this.state.uri,
            resource_id: this.props.resource_id,
            data: formDataEdit
        }).then(response => {
            this.setState( { loading: false } );
            showSuccessMessage(this, "Cliente", "Se editó satisfactoriamente");
            this.props.history.push( '/clients' );
        }).catch(error => {
            let messageError = error.response.data.message;

            if (messageError.gateway_waba !== undefined){
                showErrorMessage(this, "Whatsapp", 'Error en la configuración de whatsapp');
                delete messageError.gateway_waba
            }

            let providerFormEdit = {
                ...this.state.providerFormEdit
            };

            let providerPaymentServiceFormError = {
                ...this.state.providerPaymentServiceFormError
            };

            Object.keys(providerPaymentServiceFormError.errorProviderPaymentService).map(error => {
                if (!(error in messageError)){
                    delete providerPaymentServiceFormError.errorProviderPaymentService[error];
                }
                return providerPaymentServiceFormError;
            });

            let errorMessages = messageProviderErrorObject(providerFormEdit, providerPaymentServiceFormError, messageError);

            let providerPaymentServiceError = {'errorProviderPaymentService': null};

            if (errorMessages !== null){
                if(errorMessages?.ai_assistant_integration?.value === false && messageError){
                    showErrorMessage(this, "Cliente", messageError);
                }
                providerPaymentServiceError['errorProviderPaymentService'] = errorMessages.errorProviderPaymentService;
                delete errorMessages.errorProviderPaymentService;
                errorMessages = this.updateColsClass(errorMessages);
                this.setState({
                    providerFormEdit: errorMessages,
                    providerPaymentServiceFormError: providerPaymentServiceError,
                    loading: false
                });
            } else {
                this.setState( { loading: false } );
                showErrorMessage(this, "Cliente", messageError);
            }
        });
    };

    inputChangedHandler = (event, inputIdentifier) => {
        let inputFormChanged = this.props.action !== 'edit' ? this.state.providerForm  : this.state.providerFormEdit;

        const updatedProviderForm = {
            ...inputFormChanged
        };

        const updatedFormElement = {
            ...updatedProviderForm[inputIdentifier]
        };

        if (updatedFormElement.elementType === 'providerPaymentService'){
            updatedFormElement.value = event.value;
        } else if (updatedFormElement.elementType === 'whatsappGatewayService'){
            updatedFormElement.value = event.value;
        }  else if (updatedFormElement.elementType === 'whatsappMaxNotification'){
            updatedFormElement.value = event;
        } else {
            updatedFormElement.value = event.target.value;
        }

        updatedProviderForm[inputIdentifier] = updatedFormElement;

        if (updatedFormElement.elementConfig.error !== undefined){
            updatedFormElement.elementConfig.error = null;
        }

        if (this.props.action !== 'edit'){
            this.setState({providerForm: updatedProviderForm});
        } else {
            this.setState({providerFormEdit: updatedProviderForm});
        }
    };

    render(){
        if (this.state.loading){
            return <CustomSpinner/>;
        }
        const formElementsArray = [];
        let providerForm = this.props.action !== 'edit' ? this.state.providerForm : this.state.providerFormEdit;
        let submitForm = this.props.action !== 'edit' ? this.providerHandler : this.providerEditHandler;
        let submitFormButton = this.props.action !== 'edit' ? 'Guardar' : 'Actualizar';

        for (let key in providerForm) {
            formElementsArray.push({
                id: key,
                config: providerForm[key]
            });
        }


        let form = (
                <form onSubmit={submitForm}>
                    {formElementsArray.map(formElement => (
                        <InputForm
                            key={formElement.id}
                            elementType={formElement.config.elementType}
                            disabled={formElement.config.disabled}
                            elementConfig={formElement.config.elementConfig}
                            value={formElement.config.value}
                            typeElement={formElement.id}
                            traduction={formElement.config.traduction}
                            title={formElement.config.title}
                            invalid={!formElement.config.valid}
                            shouldValidate={formElement.config.validation}
                            touched={formElement.config.touched}
                            fields={formElementsArray}
                            dependenceOf={formElement.config.dependenceOf}
                            providerPaymentServiceFormError={this.state.providerPaymentServiceFormError}
                            providerWhatsappGatewayServiceFormError={this.state.providerWhatsappGatewayServiceFormError}
                            colsClass={formElement.config.colsClass}
                            changed={(event) => this.inputChangedHandler(event, formElement.id)}
                            url={this.state.url}
                            files={this.state.files}
                            server={this.state.server}
                        />

                    ))}

                    <div className="col-12 md-12">
                        <Button label={submitFormButton} style={{marginBottom:'10px'}} />
                    </div>

                </form>
        );

        return (
            <div className="grid p-fluid">
                <div className="col-12 p-lg-12">
                    <div className="card card-w-title">
                        <h1><strong>{this.state.title}</strong></h1>
                        <div className="col-12 form-group">
                            {form}
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapDispatchToProps = {
    showToast
};

export default withRouter(connect(null, mapDispatchToProps)(Provider));